import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import "./styles.scss";
import { CTAButton } from "../../../Components/CTAButton";
import Select from "react-select";
// import { GetAllCustomersListCommonService } from "../../../Services/CommonServices";
import Autocomplete from "react-autocomplete";
import Swal from "sweetalert2";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";
import { FixedSizeList as List } from 'react-window';

export const ShopOnBehalf = () => {
  const { getRequest } = ApiResponseHook();

  // const [isDisabled, setIsDisabled] = useState(true);
  // const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  // console.log("🚀 ~ ShopOnBehalf ~ allCustomers:", allCustomers)

  // const [value, setValue] = useState("");
  const [selectedCustNo, setSelectedCustNo] = useState("");

  useEffect(() => {
    getAllCustomersList();
  }, []);

  const showShopping = () => {
    if (selectedCustNo === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select the customer`,
      });
    } else {
      let custno = selectedCustNo;

      let encObj = {
        sbofc: custno,
      };

      let encryptedStr = JSON.stringify(encObj);

      let encrypted = btoa(encryptedStr);

      // var url =
      //   "http://pennymead.s3-website-us-east-1.amazonaws.com/?sbofc=" +
      //   encrypted;

      //  PROD LINK!!!!
      // var url = "https://pennymead.com/?sbofc=" + encrypted;

      //  STAGING LINK !!!!
      var url = "https://staging.pennymead.com/?sbofc=" + encrypted;

      // https://staging.pennymead.com

      // setValue("");
      setSelectedCustNo("");
      window.open(url, "_blank");
    }

    // const convertToBase64 = (num) => {
    //   // Convert the number to a string and then to base64
    //   return btoa(num);
    // };
  };

  const getAllCustomersList = async () => {
    // let res = await GetAllCustomersListCommonService();
    let res = await getRequest(configURL.get_all_customers_master);

    if (res.status === 200 || res?.status === 201) {
      let data = (res?.data?.data || []).map(item => {
        item = { ...item, value: item.custno }
        return item
      })
      setAllCustomers(data);
    } else {
      // console.log("error getting all the customers list", res);
    }
  };

  const formatOptionLabel = ({ name, country_name, town }) => (
    <div>
      <div><strong>Name:</strong> {name}</div>
      <div className="d-flex">
        <p className="mb-0 mr-3">
          <strong>Country :</strong> {country_name}
        </p>
        <p className="mb-0">
          <strong>Town :</strong> {town}
        </p>
      </div>
    </div>
  );

  const filterOption = (option, inputValue) => {
    return option.data.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  const MenuList = props => {
    const { options, children, maxHeight, getValue } = props;
    const height = 55;

    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  const handleChange = (value) => {
    // console.log("🚀 ~ handleChange ~ value:", value)
    if (value) {
      setSelectedCustNo(value.custno)
    } else {
      setSelectedCustNo("")
    }
  }

  return (
    <div>
      <div>
        <BreadCrumb routename="Shop on behalf" />
      </div>

      {/* {isDisabled && (
        <>
          <div className="my-3">
            <h5 className="text-danger mb-0">
              Please select a customer from the dropdown
            </h5>
          </div>
        </>
      )} */}

      <div className="shoponbehalfdiv my-4">
        <h6>Select the customer to shop for</h6>

        <div className="my-4 selectmaindiv">
          <Select
            options={allCustomers}
            formatOptionLabel={formatOptionLabel}
            filterOption={filterOption}
            components={{ MenuList }}
            onChange={handleChange}
            isClearable
          />
          {/**
          <div
          // style={{
          //   display: "flex",
          //   flexDirection: "column",
          //   alignItems: "center",
          // }}
          >
            <div>
              <Autocomplete
                // Items is the list of suggestions
                // displayed while the user type

                items={allCustomers}
                // To handle the case that when
                // the user type, suggested
                // values should be independent
                // of upper or lower case
                shouldItemRender={(item, value) =>
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.custno.toString()}
                renderItem={(item, isHighlighted) => (
                  <div
                    className="dropdowndiv"
                    style={{
                      background: isHighlighted ? "#bcf5bc" : "white",
                    }}
                    key={item.custno}
                  >
                    <div className="p-2">
                      <p className="mb-0">
                        <strong>Name:</strong> {item.name}
                      </p>

                      <div className="showcountryandtown d-flex">
                        <p className="mb-0 mr-2">
                          <strong>Country :</strong> {item?.country_name}
                        </p>
                        <p className="mb-0">
                          <strong>Town :</strong> {item?.town}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                value={value}
                // The onChange event watches for
                // changes in an input field
                onChange={(e) => setValue(e.target.value)}
                // To set the state variable to value
                // selected from dropdown
                onSelect={(val) => {
                  setSelectedCustNo(val); // Set the selected custno
                  const selected = allCustomers.find(
                    (customer) => customer.custno.toString() === val
                  );
                  setValue(selected ? selected.name : ""); // Set the input value to the selected name
                }}
                // Added style in Autocomplete component
                inputProps={{
                  style: {
                    width: "300px",
                    height: "40px",
                    background: "#e4f3f7",
                    border: "2px outset #f3f3f3",
                  },
                  placeholder: "Search customer",
                }}
              />
            </div>
          </div>
          */}


        </div>
        <div className="my-2 mx-2">
          <CTAButton
            name="Go Shopping"
            // isDisabled={isDisabled}
            handleClick={showShopping}
          />
        </div>
      </div>
    </div>
  );
};
